import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StyledNavItem } from './styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'

interface NavItemProps {
  label: string
  path?: string
  expandable?: boolean
  active: boolean
  children?: { label: string; path: string }[]
}

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
}

const NavItem = ({
  expandable,
  path,
  label,
  active,
  children,
}: NavItemProps) => {
  const [expanded, setExpanded] = useState(false)

  if (expandable) {
    return (
      <StyledNavItem
        expanded={expanded}
        active={active}
        onMouseLeave={() => setExpanded(false)}
      >
        {path ? (
          <Link
            to={path}
            onMouseOver={() => setExpanded(true)}
          >
            {label}
            <FontAwesomeIcon
              icon={faAngleDown}
              className="arrow-down"
            />
          </Link>
        ) : (
          <div onMouseOver={() => setExpanded(true)}>
            {label}
            <FontAwesomeIcon
              icon={faAngleDown}
              className="arrow-down"
            />
          </div>
        )}
        {expanded && <div className="connector"></div>}
        <div className="content">
          {children?.map(({ label, path }, index) => {
            const url =
              typeof window !== 'undefined'
                ? window.location.pathname
                : ''
            return (
              <StyledNavItem
                key={index}
                active={path === url}
                onClick={() => setExpanded(false)}
              >
                <Link key={index} to={path}>
                  {label}
                </Link>
              </StyledNavItem>
            )
          })}
        </div>
      </StyledNavItem>
    )
  }

  if (!expandable && path) {
    return (
      <StyledNavItem active={active}>
        <Link to={path}>{label}</Link>
      </StyledNavItem>
    )
  }

  return <>{label}</>
}

export default NavItem
