const MainRoutes = [
  { label: 'Educators', path: '/educators' },
  { label: 'Businesses', path: '/businesses' },
  {
    label: 'Learners',
    path: '/learners',
    expandable: true,
    children: [
      {
        label: 'Sales Mastery',
        path: '/learners/SalesMastery',
      },
    ],
  },
  {
    label: 'Features',
    path: '/features',
  },
  {
    label: 'Case Studies',
    expandable: true,
    children: [
      {
        label: 'BSI success story',
        path: '/CaseStudies/Bsi',
      },
      {
        label: 'CIMSPA success story',
        path: '/CaseStudies/Cimspa',
      },
      {
        label: 'COUTTS success story',
        path: '/CaseStudies/Coutts',
      },
      {
        label: 'Premier Global success story',
        path: '/CaseStudies/PremierGlobal',
      },
    ],
  },
  // { label: 'Blog', path: '/blog' },
  { label: 'Consultancy', path: '/consultancy' },
  { label: 'About Us', path: '/about-us' },
]

export default MainRoutes
