import { motion } from 'framer-motion'
import styled from 'styled-components'

export const StyledHeader = styled.header<{ isOpen: boolean }>`
  .static {
    background: #fdfdfd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    padding: 16px 165px;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    min-height: 72px;
    z-index: 99999;

    .suadaLogo {
      height: 56px;
    }

    .mobile-logo {
      display: none;
    }

    .motion-logo-container,
    .mobile-menu-text {
      color: #636369;
      font-family: 'Poppins-Light', sans-serif;
      display: none !important;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
      padding: 14px 100px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      padding: 12px 50px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
      height: 72px;

      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      transition: height 0.3s ease-in-out;

      nav {
        height: 60%;
        font-family: 'Poppins-Bold', sans-serif;
      }
      .mobile-logo {
        display: block;
      }
      .laptop-logo {
        display: none;
      }
      .motion-logo-container,
      .mobile-menu-text {
        display: flex !important;
      }
      .suadaLogo {
        position: absolute;
        left: 50px;
        top: 16px;
        z-index: 99999;
      }
      .custom-button {
        display: none;
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
      padding: 12px 20px;
      .suadaLogo {
        left: 30px;
        transform: scale(1.3);
        top: 20px;
      }
    }
  }
`
export const StyledNavigation = styled.nav`
  display: flex;
  justify-content: space-between;

  & > div:not(:last-child) > a {
    margin-right: 24px;
  }

  & > div:not(:last-child) > :not(a) {
    margin-right: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    display: none;
  }
`

export const StyledBurger = styled.button<{ open: boolean }>`
  position: absolute;
  right: 50px;
  top: 22px;
  user-select: none;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    display: flex;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    right: 20px;
  }
  flex-direction: column;
  justify-content: space-around;
  width: 1.55rem;
  user-select: none;
  height: 1.55rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.55rem;
    height: 0.15rem;
    background: rgba(134, 54, 204, 1);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    position: relative;
    transform-origin: 1px;
    overflow: hidden;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) =>
        open ? 'translateX(-20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`

export const MobileNavContainer = styled(motion.div)`
  display: flex;
  position: fixed;
  top: 0;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 12px;
  background: #fff;
  z-index: 99;
  padding: 10% 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.ws}) {
    display: none;
  }

  .book-a-demo {
    width: 100%;
    margin-bottom: 5vh;
  }
`

export const MobileNav = styled(motion.nav)`
  display: flex;
  flex-direction: column;
  align-items: left;
  position: absolute;
  gap: 9%;
  font-size: 21px;
  height: 60%;
  top: 60px;
  padding-top: 60px;
  a,
  div {
    font-family: 'Poppins-Bold', sans-serif;
    font-size: 21px;
    color: #000000;
  }
  @media (max-height: 800px) {
    top: 40px;
    gap: 8%;
  }
  @media (max-height: 700px) {
    gap: 5%;
  }
`

export const NavColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    .title {
      margin-top: 20px;
      text-align: left;
      font-size: 16px;
      margin-bottom: 14px;
      color: black;
      font-family: 'Poppins', sans-serif;
    }

    a {
      width: calc(100% - 40px);
      .custom-button {
        padding-inline: 0;
        width: 100%;
        justify-content: center;
      }
    }
  }
`

export const fadeUp = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

export const fadeDown = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -50,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

export const variants = {
  open: {
    x: 0,
    opacity: 1,
    width: '80vw',
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.07,
      delayChildren: 0.37,
    },
  },
  closed: {
    x: '-100vw',
    opacity: 0,
    width: 0,
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0.1,
    },
  },
}

export const initial = {
  x: '-100vw',
  opacity: 0,
  width: 0,
  height: '100vh',
}

export const tabVariants = {
  open: {
    x: 0,
    opacity: 1,
    width: '70vw',
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.05,
      delayChildren: 0.16,
    },
  },
  closed: {
    x: '-100vw',
    opacity: 0,
    width: '70vw',
    transition: {
      ease: 'easeInOut',
      staggerChildren: 0.05,
      staggerDirection: -1,
      delay: 0,
    },
  },
}

export const expandableTabStyles = {
  display: 'flex',
  gap: 20,
  alignItems: 'center',
  zIndex: 9999,
  minWidth: '40vw',
  minHeight: '100%',
  cursor: 'pointer',
}
