import styled from 'styled-components'

export const StyledNavItem = styled.div<{
  expanded?: boolean
  active: boolean
}>`
  cursor: pointer;
  color: #000;
  text-decoration: none;
  font-size: 14px;
  color: ${(props) => (props.active ? '#06c68f' : '#000')};
  text-decoration: none;
  font-size: 14px;
  cursor: default;
  transition: color 0.3s ease;
  position: relative;

  & > a {
    transition: color 0.3s ease;
    color: ${(props) =>
      props.active ? '#06c68f' : '#000'};
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;

    .arrow-down {
      margin-left: 8px;
      transition: transform 0.3s ease-in-out;
      transform: ${(props) =>
        props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
    }
  }

  &:hover > a,
  &:hover {
    color: #06c68f;
  }

  .arrow-down {
    margin-left: 8px;
    transition: transform 0.3s ease-in-out;
    transform: ${(props) =>
      props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
  }

  .connector {
    position: absolute;
    z-index: 99999;
    width: 100%;
    height: 60px;
    top: 20px;
    left: 0;
  }

  .content {
    position: absolute;
    top: 55px;
    visibility: ${({ expanded }) =>
      expanded ? 'visible' : 'hidden'};
    height: ${({ expanded }) => (expanded ? 'auto' : '0')};
    padding: ${({ expanded }) =>
      expanded ? '16px' : '0 16px'};
    width: 240px;
    background: #fdfdfd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    background: #fff;
    display: flex;
    z-index: 99999;
    flex-direction: column;
    gap: 16px;
    transition: all 0.3s;

    a {
      color: #000;
      visibility: ${({ expanded }) =>
        expanded ? 'visible' : 'hidden'};
    }

    a:hover {
      color: #06c68f;
    }
  }
`
