// import Title from 'components/common/Text/StyledText'
import { Link } from 'gatsby'
import { MainRoutesProps } from 'interfaces/MainRoutesProps'
import React from 'react'
// import { SocialMediaIconsContainer } from '../Footer/styled-components'
import {
  expandableTabStyles,
  fadeUp,
  initial,
  MobileNav,
  MobileNavContainer,
  NavColumnWrapper,
  tabVariants,
  variants,
} from './styled-components'
import { StyledButton } from 'components/common'

// import FacebookIconDark from 'images/svg/facebook-dark.svg'
// import TwitterIconDark from 'images/svg/twitter-dark.svg'
// import YoutubeIconDark from 'images/svg/youtube-dark.svg'
// import InstagramIconDark from 'images/svg/instagram-dark.svg'
// import TumblrIconDark from 'images/svg/tumblr-dark.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

const MobileNavigation = ({
  animate,
  MainRoutes,
  onTabChange,
  onClose,
  activeTab,
}: MainRoutesProps) => {
  return (
    <MobileNavContainer
      initial={initial}
      animate={animate}
      variants={variants}
    >
      <MobileNav
        animate={activeTab === 'Menu' ? 'open' : 'closed'}
        variants={tabVariants}
      >
        {MainRoutes.map(({ path, label }, index) => {
          return path ? (
            <motion.li key={index} variants={fadeUp}>
              <Link to={path} onClick={onClose}>
                <p>{label}</p>
              </Link>
            </motion.li>
          ) : (
            <motion.li
              key={index}
              variants={fadeUp}
              onClick={() => onTabChange(label)}
            >
              <div style={expandableTabStyles}>
                {label}
                <FontAwesomeIcon icon={faAngleRight} />{' '}
              </div>
            </motion.li>
          )
        })}
      </MobileNav>
      <MobileNav
        animate={
          activeTab === 'Case Studies' ? 'open' : 'closed'
        }
        variants={tabVariants}
      >
        {MainRoutes.find(
          ({ label }) => label === activeTab,
        )?.children?.map(({ path, label }, index) => {
          return path ? (
            <AnimatePresence key={index}>
              <motion.li
                animate={{ x: 0, opacity: 1 }}
                initial={{ x: -400, opacity: 0 }}
                transition={{
                  delay: index * 0.08,
                  ease: 'easeInOut',
                  x: {
                    duration: 0.4,
                    stiffness: 1000,
                    damping: 500,
                    ease: 'easeOut',
                  },
                  opacity: {
                    duration: 0.7,
                    stiffness: 1000,
                    damping: 500,
                  },
                }}
              >
                <Link to={path} onClick={onClose}>
                  {label}
                </Link>
              </motion.li>
            </AnimatePresence>
          ) : (
            <motion.li variants={fadeUp} key={index}>
              <div onClick={() => onTabChange(label)}>
                {label}
              </div>
            </motion.li>
          )
        })}
      </MobileNav>
      <motion.div className="book-a-demo" variants={fadeUp}>
        <NavColumnWrapper>
          <StyledButton
            to="https://calendly.com/suada-david-thomson/suada-platform-demonstration"
            paddings={74}
          >
            See it in action
          </StyledButton>
          {/* <Title textColor="white">Find us on social media</Title>
          <SocialMediaIconsContainer>
            <Link to="facebook.com">
              <FacebookIconDark />
            </Link>
            <Link to="twitter.com">
              <TwitterIconDark />
            </Link>
            <Link to="youtube.com">
              <YoutubeIconDark />
            </Link>
            <Link to="instagram.com">
              <InstagramIconDark />
            </Link>
            <Link to="tumbrl.com">
              <TumblrIconDark />
            </Link>
          </SocialMediaIconsContainer> */}
        </NavColumnWrapper>
      </motion.div>
    </MobileNavContainer>
  )
}

export default MobileNavigation
