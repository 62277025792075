import React, { useEffect, useState } from 'react'
import {
  expandableTabStyles,
  StyledBurger,
  StyledHeader,
  StyledNavigation,
} from './styled-components'
import { StyledButton } from 'components/common'
import SuadaLogo from 'images/svg/suadaLogo.svg'
import SuadaLogoMobile from 'images/svg/suadaLogoMobile.svg'
import { NavItem } from './NavItem'
import { Link } from 'gatsby'

import MainRoutes from './MainRoutes'
import MobileNavigation from './MobileNavigation'
import { GlobalStyle } from 'layouts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { motion } from 'framer-motion'

const tabVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    x: -340,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

const logoVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      x: { stiffness: 1000 },
    },
  },
  closed: {
    x: 0,
    opacity: 0,
    transition: {
      x: { stiffness: 1000 },
    },
  },
}

const Header = ({
  location,
  isOpen,
  setIsOpen,
  caseStudies,
}: {
  location: string
  isOpen: boolean
  setIsOpen: any
  caseStudies: {
    label: string
    path: string
  }[]
}) => {
  const [activeTab, setActiveTab] = useState('')

  // mobile navigation main routes
  const MobileRoutes = MainRoutes.filter(
    ({ label }) => label !== 'Contact Us',
  )
  MobileRoutes.splice(3, 0, {
    label: 'Sales Mastery',
    path: '/learners/SalesMastery',
  })
  useEffect(() => {
    !isOpen &&
      setTimeout(() => {
        setActiveTab('')
      }, 300)
  }, [isOpen])

  const handleMobileNavClick = (activeUrl: string) => {
    setActiveTab(activeUrl)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleBurgerClick = () => {
    if (isOpen) {
      setIsOpen(false)
      setActiveTab('')
    } else {
      setIsOpen(true)
      setActiveTab('Menu')
    }
  }

  return (
    <StyledHeader isOpen={isOpen}>
      <GlobalStyle isNavOpen={isOpen} />
      <div className="static">
        <Link
          to="/"
          className="suadaLogo laptop-logo"
          aria-label="Navigate to home page"
        >
          <SuadaLogo />
        </Link>
        <motion.div
          animate={
            activeTab === 'Menu' || activeTab === ''
              ? 'open'
              : 'closed'
          }
          variants={logoVariants}
          className="motion-logo-container"
        >
          <Link
            to="/"
            onClick={() => setIsOpen(false)}
            className="suadaLogo mobile-logo"
            aria-label="Navigate to home page"
          >
            <SuadaLogoMobile />
          </Link>
        </motion.div>
        <motion.div
          variants={tabVariants}
          initial="closed"
          animate={
            activeTab === 'Case Studies' ? 'open' : 'closed'
          }
          className="mobile-menu-text"
          onClick={() => setActiveTab('Menu')}
          style={expandableTabStyles}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> {activeTab}
        </motion.div>
        <StyledNavigation>
          {MainRoutes.map(
            (
              { path, label, expandable, children },
              index,
            ) => {
              if (label === 'Case Studies')
                children = caseStudies
              return (
                <NavItem
                  key={index}
                  path={path}
                  label={label}
                  expandable={expandable}
                  children={children}
                  active={location === path}
                />
              )
            },
          )}
        </StyledNavigation>
        <StyledButton to="https://calendly.com/suada-david-thomson/suada-platform-demonstration">
          See it in action
        </StyledButton>
        <StyledBurger
          aria-label="Burger Menu Button"
          onClick={handleBurgerClick}
          open={isOpen}
        >
          <div />
          <div />
          <div />
        </StyledBurger>
      </div>
      <MobileNavigation
        animate={isOpen ? 'open' : 'closed'}
        MainRoutes={MobileRoutes}
        onTabChange={handleMobileNavClick}
        onClose={handleClose}
        activeTab={activeTab}
      />
    </StyledHeader>
  )
}
export default Header
